<ng-container *cftI18n="let t">
  <cft-info-box
    *ngIf="{
      title: t('support.contactCard.title')
    } as ctx"
    [title]="showTitle ? title || ctx.title : ''"
    [bgColor]="bgColor"
    [icon]="icon"
    [hasShadow]="hasShadow"
  >
    <div class="mt-xs text-base" *ngIf="description">{{ description }}</div>
    <div
      class="flex space-x-md"
      [class.mt-lg]="description"
      [ngClass]="{'items-center': layout === 'horizontal', 'items-start': layout === 'vertical'}"
    >
      <div class="flex justify-center">
        <ng-content select="[content=portrait],cft-support-portrait"></ng-content>
      </div>
      <div class="flex grow">
        <div [ngClass]="{'flex w-full items-center justify-between space-x-lg': layout === 'horizontal'}">
          <div>
            <div class="heading-base">{{ contact.name }}</div>
            <div class="text-sm">{{ contact.role }}</div>
            <div class="text-xs text-president-200" *ngIf="operatingHours">{{ operatingHours }}</div>
          </div>
          <div *ngIf="contact.email || contact.phone">
            <ng-container
              *ngTemplateOutlet="layout === 'horizontal' ? horizontalContact : verticalContact"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-content select="[content=footer]"></ng-content>
  </cft-info-box>
</ng-container>

<ng-template #verticalContact>
  <div class="mt-sm flex flex-col gap-2xs text-sm font-medium">
    <a *ngIf="contact.email" [href]="contact.email | bgoMailTo">
      <fa-icon [icon]="emailIcon" class="mr-xs"></fa-icon>
      {{ contact.email }}
    </a>
    <a *ngIf="contact.phone" [href]="contact.phone | bgoTel">
      <fa-icon [icon]="phoneIcon" class="mr-xs"></fa-icon>
      {{ contact.phone }}
    </a>
  </div>
</ng-template>

<ng-template #horizontalContact>
  <div class="flex items-center space-x-sm">
    <a
      class="btn cursor-pointer rounded bg-grey-200 p-sm text-sm"
      [ngClass]="{'rounded-lg': ctaButtonsRoundingStyle === 'rounded-lg'}"
      *ngIf="contact.email"
      [href]="contact.email | bgoMailTo"
    >
      <fa-icon [icon]="emailIcon"></fa-icon>
    </a>
    <a
      class="btn cursor-pointer rounded bg-grey-200 p-sm text-sm"
      [ngClass]="{'rounded-lg': ctaButtonsRoundingStyle === 'rounded-lg'}"
      *ngIf="contact.phone"
      [href]="contact.phone | bgoTel"
    >
      <fa-icon [icon]="phoneIcon"></fa-icon>
    </a>
  </div>
</ng-template>
