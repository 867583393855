import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {SupportBoxComponent} from './support-box.component'
import {PipesModule} from '../../../shared/pipes/pipes.module'
import {DirectivesModule} from '../../../shared/directives/directives.module'
import {SupportPortraitComponent} from '../support-portrait/support-portrait.component'
import {InfoBoxModule} from '../../info-box/info-box.module'
import {I18nDirective} from '../../i18n/i18n.directive'

@NgModule({
  declarations: [SupportBoxComponent],
  exports: [SupportBoxComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    InfoBoxModule,
    PipesModule,
    DirectivesModule,
    SupportPortraitComponent,
    I18nDirective,
  ],
})
export class SupportBoxModule {}
