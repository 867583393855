import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faPhoneAlt} from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope'

/**
 * This component takes children content:
 * content=portrait / <cft-support-portrait> -> displayed as the contact portrait
 * content=footer -> displayed as the footer of the box
 */
@Component({
  selector: 'cft-support-box',
  templateUrl: './support-box.component.html',
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportBoxComponent {
  @Input() contact!: ContactInformation
  @Input() icon?: IconDefinition
  @Input() title?: string
  @Input() description?: string
  @Input() layout: 'vertical' | 'horizontal' = 'vertical'
  @Input() operatingHours?: string
  @Input() showTitle = true
  @Input() ctaButtonsRoundingStyle: 'rounded' | 'rounded-lg' = 'rounded'
  @Input() hasShadow = true
  @Input() bgColor: 'white' | 'grey' = 'white'

  readonly phoneIcon = faPhoneAlt
  readonly emailIcon = faEnvelope
}

export interface ContactInformation {
  name: string
  role: string
  email?: string
  // add `undefined` explicit to avoid type errors in templates
  phone?: string | undefined
}
